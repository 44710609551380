<template>
    <b-overlay :show="loading">
        <form-filter-export @onExport="doExport" :type_export="['excel']">
            <template v-if="hasPermissionPay || isOwner" #default>
                <b-form-group label="Jenis Pembelian">
                    <v-select :options="[{value: '', text: 'Semua'}, {value: 1, text: 'Rokok'}, {value: 2, text: 'Non-Rokok'}]" label="text" :reduce="option => option.value" v-model="selectedJenis"></v-select>
                </b-form-group>
            </template>
            <template #dropdown-item="{form}">
                <b-dropdown v-if="hasPermissionPay || isOwner" variant="outline-primary" text="Export">
                    <b-dropdown-item href="#" @click.prevent="doExport(form, 'pembelian')">Pembelian</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="doExport(form, 'pembayaran')">Pembayaran</b-dropdown-item>
                </b-dropdown>
                <b-dropdown v-else-if="isGM" variant="outline-primary" text="Export">
                    <b-dropdown-item href="#" @click.prevent="doExport(form, 'pembelian')">Pembelian</b-dropdown-item>
                </b-dropdown>
            </template>
        </form-filter-export>
        <b-row class="mb-2">
            <!-- Header -->
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" v-if="allowCreate(modulePembelian) && !hasPermissionPay" @click.prevent="add">Tambah</b-button>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->

        </b-row>
        <!-- Body -->
        <b-card>
            <b-row>
                <b-col cols="12">
                    <b-table :filter="filter" :per-page="perPage" :current-page="currentPage" responsive :fields="fields" :items="pembelians">
                        <template #cell(order)="{index}">
                            {{++index}}
                        </template>
                        <template #cell(jenis)="{item}">
                            {{item.jenis == 1 ? 'Rokok' : 'Non-Rokok'}}
                        </template>
                        <template #cell(tanggal)="{item}">
                            {{ humanDate(item.tanggal) }}
                        </template>
                        <template #cell(total)="{item}">
                            <span v-if="item.informasi">
                                Rp {{  formatRupiah(item.informasi.total_pembelian)  }}
                            </span>
                            <i class="text-danger" v-else>
                                Tidak ada informasi
                            </i>
                        </template>
                        <template #cell(status)="{item}">
                            <b-badge v-if="item.informasi && item.informasi.hutang == 0" variant="light-success">
                                Lunas
                            </b-badge>
                            <b-badge v-else variant="light-danger">Belum Lunas</b-badge>
                        </template>
                        <template #cell(actions)="{item}">
                            <div class="flex align-items-center justify-center">
                                <b-button
                                    size="sm"
                                    @click="detail(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowUpdate(modulePembelian) && item.informasi.total_bayar < 1 && !hasPermissionPay"
                                    size="sm"
                                    @click="edit(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowDelete(modulePembelian) && !hasPermissionPay"
                                    size="sm"
                                    @click="remove(item)"
                                    class="mr-1"
                                    variant="outline-danger"
                                    >
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
    </b-overlay>
</template>
<script>
import {BOverlay, BDropdown, BDropdownItem, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect} from 'bootstrap-vue'
import service from '@/services'
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import vSelect from 'vue-select'
export default {
    components: {vSelect,
        FormFilterExport,
        BOverlay, BDropdown, BDropdownItem,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect
    },
    data: () => ({
        selectedJenis: 2,
        modulePembelian: 'Pembelian',
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
        {key: 'order', label: 'No'},
          {key: 'no', label: 'No. Pembelian'},
          {key: 'tanggal', label: 'Tanggal'},
          {key: 'instansi', label: 'Supplier'},
          {key: 'total', label: 'Total Pembelian'},
          {key: 'status', label: 'Pembayaran'},
          {key: 'actions', label: '#'}
        ],
        pembelians: [],
        jenis: null,
        menu: null,
        myDataOnly: false,
        hasPermissionPay: false
    }),
    methods: {
        async doExport({tgl_awal, tgl_akhir}, type_export = 'pembayaran') {
            if(!tgl_awal || !tgl_akhir) {
                this.displayError({
                message: 'Harap isi tanggal awal dan akhir!'
                })
                return false
            }

            if(this.selectedJenis == null) {
                this.displayError({
                    message: 'Harap pilih jenis pembayaran'
                })
                return false
            }

            try {
                const url = type_export == 'pembayaran' ? `/excel-rekap-pembayaran-beli` : `excel-rekap-pembelian`
                const config = {
                    method: 'get',
                    url,
                    params: {
                        jenis: this.selectedJenis,
                        tgl_awal,
                        tgl_akhir
                    },
                    headers: {
                        Authorization: `bearer ${localStorage.token}`
                    }
                }

                this.loading = true
                const response = await service.sendRequest(config)
                this.loading = false
                await window.open(response.data)
                setTimeout(async () => {
                    // delete selected file
                    const arrFile = response.data.split('/')
                    const filename = arrFile[ arrFile.length - 1 ]

                    await this.clearExportedFile(filename)
                }, 1000)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async checkPermissionPay() {
            const menu = await this.currentMenu(this.$route.path)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            const listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionPay = listStatus.some(status => status.data_status == 2)
            this.hasPermissionPay = hasPermissionPay
        },
        edit(item) {
            this.$router.push(`/pembelian/edit/${item.id}`)
        },
        remove(item)  {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data pembelian ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(async res => {
                if(res.value) {
                    const payload = {
                        fungsi: 1,
                        id: item.id
                    }

                    try {
                        this.loading = true
                        await this.$store.dispatch('pembelian/save', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Data pembelian berhasil dihapus'
                        })
                        this.getPembelian()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        add() {
            this.$router.push(`/pembelian/add`)
        },
        async getPembelian() {
            const params = {
                order: 'desc'
            }
            if(this.jenis) {
                params.jenis = this.jenis
            }

            this.pembelians = await this.$store.dispatch('pembelian/getData', params)
            this.totalRows = this.pembelians.length
        },
        detail(item) {
            this.$router.push(`/pembelian/detail/${item.id}`)
        },
        async getJenisData() {
            const params = {
                level_id: this.user.level.id
            }
            if(this.menu) params.menu_id = this.menu.id

            const status = await this.$store.dispatch('statusrole/getData', params)
            const findJenis1 = status.find(st => st.data_status == 11)
            const findJenis2 = status.find(st => st.data_status == 22)

            if(findJenis1) {
                this.jenis
            }
            else if(findJenis2) {
                this.jenis = 2
            }
            else if((findJenis2 && findJenis1) || this.isOwner) {
                this.jenis = null
            }
            else {
                this.jenis = null
            }
        },
        async getCurrentMenu() {
            this.menu = await this.currentMenu()
        },
        setField() {
            if(this.hasPermissionPay || this.isFinance) {
                this.fields = [
                    {key: 'order', label: 'No'},
                    {key: 'no', label: 'No. Pembelian'},
                    {key: 'tanggal', label: 'Tanggal'},
                    {key: 'total', label: 'Total Pembelian'},
                    {key: 'status', label: 'Pembayaran'},
                    {key: 'actions', label: '#'}
                ]
            }
            else if(this.isOwner) {
                this.fields = [
                    {key: 'order', label: 'No'},
                    {key: 'no', label: 'No. Pembelian'},
                    {key: 'jenis', label: 'Jenis Pembelian Barang'},
                    {key: 'tanggal', label: 'Tanggal'},
                    {key: 'total', label: 'Total Pembelian'},
                    {key: 'status', label: 'Pembayaran'},
                    {key: 'actions', label: '#'}
                ]
            }
        }
    },
    async mounted() {
        this.checkPermissionPay()
        this.loading = true
        await this.getCurrentMenu()
        await this.getJenisData()
        await this.setField()
        await this.getPembelian()
        this.loading = false
    }
}
</script>